import { Injectable } from '@angular/core';

// Internal services
import { BaseHttpService } from '../base-http-service.service';
import { AuthInterceptorService } from 'src/app/interceptors/auth.interceptor';
import { LeagueBillingFrequency } from 'src/app/models/leagues/enums/league-billing-frequency.enum';

// Internal models


@Injectable({
  providedIn: 'root'
})
export class LeagueService extends BaseHttpService {
  constructor(authInterceptor: AuthInterceptorService) {
    super(authInterceptor);
  }

  // ! POST Requests
  async createLeagueRegistration(leagueId: string, body: any, discountCode: string = null, retry = true): Promise<any | null> {
    let endpoint = '';
    if (discountCode !== null && discountCode !== '' && discountCode !== undefined) {
      endpoint = `/spotlite-proxy/Leagues/${leagueId}/register/${discountCode}`;
    } else {
      endpoint = `/spotlite-proxy/Leagues/${leagueId}/register`;
    }
    return this.post<any>(endpoint, body, retry);
  }
  async logPendingLeagueRegistration(leagueId: string, body: any, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/Leagues/${leagueId}/pendingRegistration`;
    return this.post<any>(endpoint, body, retry);
  }
  async createStripeCustomer(corporationDetailId: string, body: any, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/Leagues/stripeCustomer/${corporationDetailId}`;
    return this.post<any>(endpoint, body, retry);
  }
  
  // ! GET Requests
  async getLeagueRegistrationFormFields(leagueId: string, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/Leagues/RegistrationFormFields?lid=${leagueId}`;
    return this.get<any>(endpoint, retry);
  }

  async getLeagueDetails(corporationDetailId: string, leagueId: string, discountCode: string = null, retry = true): Promise<any | null> {
    let endpoint = `/spotlite-proxy/Leagues/${corporationDetailId}/${leagueId}`;
    if (discountCode !== null && discountCode !== '' && discountCode !== undefined) {
      endpoint = `/spotlite-proxy/Leagues/${corporationDetailId}/${leagueId}/discount/${discountCode}`;
    }
    return this.get<any>(endpoint, retry);
  }

  async getLeaguePaymentAmount(leagueId: string, billingFrequency: LeagueBillingFrequency, isRegistration: boolean, discountCode: string = null, retry = true): Promise<any | null> {
    let endpoint = '';
    if (discountCode !== null && discountCode !== '' && discountCode !== undefined) {
      endpoint = `/spotlite-proxy/Leagues/${leagueId}/paymentAmount/${billingFrequency}/${discountCode}?isRegistration=${isRegistration}`;
    } else {
      endpoint = `/spotlite-proxy/Leagues/${leagueId}/paymentAmount/${billingFrequency}?isRegistration=${isRegistration}`;
    }
    return this.get<any>(endpoint, retry);
  }

  async getLeagueShortLinkDetails(shortLinkId: string, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/Leagues/shortLink/${shortLinkId}`;
    return this.get<any>(endpoint, retry);
  }

  async getShortLinkDetailsByLeagueId(leagueId: string, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/Leagues/${leagueId}/shortLinkDetails`;
    return this.get<any>(endpoint, retry);
  }

  async getSetupIntentClientSecret(corporationDetailId:string, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/Leagues/setupIntent/${corporationDetailId}`;
    return this.get<any>(endpoint, retry);
  }

  async getDiscountDetails(leagueId: string, discountCode: string, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/Leagues/${leagueId}/discount/${discountCode}`;
    return this.get<any>(endpoint, retry);
  }

  async getReferralDetails(referralCode: string, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/Leagues/referralCode/${referralCode}`;
    return this.get<any>(endpoint, retry);
  }

  async getReferralRegistrations(referralCode: string, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/Leagues/referralCode/${referralCode}/registrations`;
    return this.get<any>(endpoint, retry);
  }

  async getReferralRewardsByCodeId(referralCodeId: string, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/Leagues/referralCode/${referralCodeId}/rewards`;
    return this.get<any>(endpoint, retry);
  }

  // ! PUT Requests
  async attachPaymentMethod(corporationDetailId: string, customerId: string, paymentMethodId: string, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/Leagues/attachPaymentMethod/${corporationDetailId}/${customerId}/${paymentMethodId}`;
    return this.put<any>(endpoint, {}, retry);
  }
  
}