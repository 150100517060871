import { APP_INITIALIZER, ErrorHandler, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

// Other external imports
import * as Sentry from '@sentry/capacitor';
import * as SentryAngular from '@sentry/angular';
import { QuillModule } from 'ngx-quill';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// Internal providers
import { AcceptInviteProvider } from './providers/accept-invite-provider/accept-invite.provider';
import { ManageReservationProvider } from './providers/manage-reservations-provider/manage-reservation.provider';
import { MembershipsProvider } from './providers/memberships-provider/membership.provider';
import { StripeProvider } from './providers/stripe-provider/stripe.provider';

//Interal Services
import { AdminService } from './services/admin-service/admin.service';
import { AuthService } from './services/auth-service/auth.service';
import { BaseHttpService } from './services/base-http-service.service';
import { GuestInvitationService } from './services/guest-invitation-service/guest-invitation.service';
import { LeagueService } from './services/league-service/league.service';
import { MembershipService } from './services/membership-service/membership.service';
import { ReservationsService } from './services/reservations-service/reservations.service';
import { SmsService } from './services/sms-service/sms.service';
import { SpotliteAuthService } from './services/spotlite-auth-service/spotlite-auth.service';
import { StripeService } from './services/stripe-service/stripe.service';
import { VenueService } from './services/venue-service/venue.service';

// Interceptors
import { AuthInterceptorService } from './interceptors/auth.interceptor';

//Other Internal Imports
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';


Sentry.init({
  dsn: environment.sentryDsn,
  tracesSampleRate: environment.sentryTracesSampleRate,
  release: `phynx@${environment.appVersion}`,
  dist: environment.appDistVersion,
  environment: environment.sentryName,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  tracePropagationTargets: [
    'localhost',
    'https://23.119.176.134:2289',
    'https://23.119.176.134:2286',
    'https://23.119.176.134:2287',
    'https://web-eagleeye-dev-fe.azurewebsites.net',
    'https://web-eagleeye-stage-fe.azurewebsites.net',
    'https://web-eagleeye-prod-fe.azurewebsites.net',
    'https://web-eagleeye-proxy-dev-be.azurewebsites.net',
    'https://web-eagleeye-proxy-stage-be.azurewebsites.net',
    'https://web-eagleeye-proxy-prod-be.azurewebsites.net',
    'https://eagleeyeapp.ai',
    'https://spotlite.app'
  ],
  replaysSessionSampleRate: environment.sentryReplaysSessionSampleRate,
  replaysOnErrorSampleRate: environment.sentryReplaysOnErrorSampleRate,
}, SentryAngular.init);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    QuillModule.forRoot(),
    IonicModule.forRoot(),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    // Providers
    AcceptInviteProvider,
    ManageReservationProvider,
    MembershipsProvider,
    StripeProvider,

    // Services
    AdminService,
    AuthService,
    BaseHttpService,
    GuestInvitationService,
    LeagueService,
    MembershipService,
    ReservationsService,
    SmsService,
    SpotliteAuthService,
    StripeService,
    VenueService,

    // Interceptors
    AuthInterceptorService,

    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: ErrorHandler,
      useValue: SentryAngular.createErrorHandler(),
    },
    {
        provide: SentryAngular.TraceService,
        deps: [Router],
    },
    {
        provide: APP_INITIALIZER,
        useFactory: () => () => {},
        deps: [SentryAngular.TraceService],
        multi: true,
    },
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule {}
