import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'confirm-reservation',
    loadChildren: () => import('./confirm-reservation/confirm-reservation.module').then( m => m.ConfirmReservationPageModule)
  },
  {
    path: 'venue',
    loadChildren: () => import('./venue/venue.module').then( m => m.VenuePageModule)
  },
  {
    path: 'manage-reservation',
    loadChildren: () => import('./manage-reservation/manage-reservation.module').then( m => m.ManageReservationPageModule)
  },
  {
    path: 'accept-invite',
    loadChildren: () => import('./accept-invite/accept-invite.module').then( m => m.AcceptInvitePageModule)
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./terms-and-conditions/terms-and-conditions.module').then( m => m.TermsAndConditionsPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'reservation-submission/success',
    loadChildren: () => import('./reservation-submission/success/success.module').then( m => m.SuccessPageModule)
  },
  {
    path: 'end-user-terms',
    loadChildren: () => import('./end-user-terms/end-user-terms.module').then( m => m.EndUserTermsPageModule)
  },
  {
    path: 'league',
    loadChildren: () => import('./league-pages/league/league.module').then( m => m.LeaguePageModule)
  },
  {
    path: 'league/registration',
    loadChildren: () => import('./league-pages/league-registration/league-registration.module').then( m => m.LeagueRegistrationPageModule)
  },
  {
    path: 'league/leaderboard',
    loadChildren: () => import('./league-pages/league-leaderboard/league-leaderboard.module').then( m => m.LeagueLeaderboardPageModule)
  },
  {
    path: 'league/schedule',
    loadChildren: () => import('./league-pages/league-schedule/league-schedule.module').then( m => m.LeagueSchedulePageModule)
  },
  {
    path: 'league-registration-successful',
    loadChildren: () => import('./league-pages/league-registration-successful/league-registration-successful.module').then( m => m.LeagueRegistrationSuccessfulPageModule)
  },
  {
    path: 'league/referral-code/:rc',
    loadChildren: () => import('./league-pages/league-referral-code/league-referral-code.module').then( m => m.LeagueReferralCodePageModule)
  },
  {
    path: 'redeem-awards',
    loadChildren: () => import('./league-pages/league-referral-code/redeem-awards/redeem-rewards.module').then( m => m.RedeemRewardsPageModule)
  },
  {
    path: 'view-referrals',
    loadChildren: () => import('./league-pages/league-referral-code/view-referrals/view-referrals.module').then( m => m.ViewReferralsPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
